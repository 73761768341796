
import { computed, defineComponent, onBeforeMount, ref } from "vue";

import { add, trashBin } from "ionicons/icons";

import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonToggle,
  IonButton,
  IonIcon,
  IonChip,
  IonList,
  IonButtons,
} from "@ionic/vue";

import SetupModuleHeader from "./SetupModuleHeader.vue";
import SetupTitleInput from "./SetupTitleInput.vue";
import SetupDoneButton from "./SetupDoneButton.vue";

import LanguageService from "../../../lib/service/LanguageService";
import CreateService from "../../../lib/service/CreateService";
import { ValidationResult } from "../../../lib/form/Validation";

export default defineComponent({
  name: "SetupVote",
  components: {
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonItem,
    IonLabel,
    IonToggle,
    IonCardHeader,
    IonCardTitle,
    IonInput,
    IonButton,
    IonIcon,
    IonChip,
    IonList,
    IonButtons,
    SetupModuleHeader,
    SetupTitleInput,
    SetupDoneButton,
  },
  setup() {
    const lang = LanguageService.getTranslator("module/vote");
    const langGeneric = LanguageService.getTranslator("generic");
    const answerInput = ref("");

    const validator = computed<ValidationResult>(() => {
      CreateService.configState.vote = false;
      if (CreateService.info.config.vote) {
        if (CreateService.info.config.vote.title.length <= 2) {
          return {
            valid: false,
            message: "titleTooShort",
          };
        }
        if (
          CreateService.info.config.vote.options.length < 2 &&
          !CreateService.info.config.vote.userAdd
        ) {
          return {
            valid: false,
            message: "tooFewOptions",
          };
        }
        CreateService.configState.vote = true;
        return {
          valid: true,
        };
      } else {
        return { valid: false };
      }
    });

    const answerInputValid = computed(() => {
      return (
        answerInput.value.length >= 2 &&
        answerInput.value.length <= 40 &&
        CreateService.info.config.vote !== undefined &&
        !CreateService.info.config.vote.options
          .map((e) => e.title)
          .includes(answerInput.value) &&
        CreateService.info.config.vote.options.length <= 100
      );
    });

    const addAnswer = () => {
      if (
        answerInputValid.value &&
        CreateService.info.config.vote !== undefined
      ) {
        CreateService.info.config.vote.options.push({
          title: answerInput.value,
        });
        answerInput.value = "";
      }
    };

    const removeAnswer = (index: number) => {
      if (CreateService.info.config.vote !== undefined) {
        CreateService.info.config.vote.options.splice(index, 1);
      }
    };

    onBeforeMount(() => {
      if (CreateService.info.config.vote === undefined) {
        CreateService.info.config.vote = {
          title: lang.t("title"),
          showResults: true,
          options: [],
          multipleChoice: false,
          userAdd: false,
        };
        CreateService.configState.vote = false;
      }
    });

    return {
      lang,
      langGeneric,
      answerInput,
      CreateService,
      trashBin,
      add,
      validator,
      answerInputValid,
      addAnswer,
      removeAnswer,
    };
  },
});
